import React, { Component } from 'react'
import AboutHeader from '../components/about/header'
import Movies from '../components/about/movies'
import Music from '../components/about/music'
import Reading from '../components/about/reading'
import Experience from '../components/experience/wrapper'
import Footer from '../components/footer'


export class About extends Component {
  render() {
    return (
        <main className="dark">
            <title>About Page</title>
            <AboutHeader />
            <Experience resume />
            {/* <Reading /> */}
            <Music />
            <Movies />
            <Footer />
        </main>
    )
  }
}

export default About