import React, { Component } from "react";
import Slider from "react-slick";
import Container from "../container";

import burna from "../../images/music/burna.png";
import labrinth from "../../images/music/labrinth.png";
import simi from "../../images/music/simi.png";
import miles from "../../images/music/miles.png";
import buju from "../../images/music/buju.png";
import dodie from "../../images/music/dodies.png";
import skibii from "../../images/music/skibii.png";
import melvito from "../../images/music/melvito.png";
import her from "../../images/music/her.png";
import wiz from "../../images/music/wiz.png";
import bryson from "../../images/music/bryson.png";
import mafo from "../../images/music/mafo.png";

export class Music extends Component {
  render() {
    const musicList = [
      {
        image: `${burna}`,
        title: "Level up",
        artiste: "Burna",
      },
      {
        image: `${labrinth}`,
        title: "Mount Everest",
        artiste: "LABRINTH",
      },
      {
        image: `${simi}`,
        title: "Woman",
        artiste: "Simi",
      },
      {
        image: `${miles}`,
        title: "There will be days like these",
        artiste: "Miles Carter",
      },
      {
        image: `${buju}`,
        title: "Never Stopped",
        artiste: "Buju",
      },
      {
        image: `${dodie}`,
        title: "She",
        artiste: "Dodie",
      },
      {
        image: `${skibii}`,
        title: "Baddest Boy",
        artiste: "skibii",
      },
      {
        image: `${melvito}`,
        title: "Santorini Coffee",
        artiste: "Melvitto",
      },
      {
        image: `${her}`,
        title: "Best Part",
        artiste: "H.E.R",
      },
      {
        image: `${wiz}`,
        title: "B. D’or",
        artiste: "Burna x wiz",
      },
      {
        image: `${bryson}`,
        title: "Don't",
        artiste: "Bryson Tiller",
      },
      {
        image: `${mafo}`,
        title: "Mafo",
        artiste: "My President",
      },
    ];

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="music">
        <div className="music__header">
          <Container>
            <h2>
              Lately
              <br />
              <span>I've been listening to</span>
            </h2>
          </Container>
        </div>
        <div className="music__list">
          <Slider {...settings}>
            {musicList.map((item) => (
              <div className="music__list__item">
                <img src={item.image} alt={item.title + item.artiste} />
                <h4>{item.title}</h4>
                <p>{item.artiste}</p>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default Music;
