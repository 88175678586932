import React, { Component } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Container from "../container";

import sounds from "../../images/movies/sounds.png";
import showman from "../../images/movies/thegreatestshowman.png";
import maleficient from "../../images/movies/maleficient.png";
import jaws from "../../images/movies/jaws.png";
import camprock from "../../images/movies/camprock.png";



const variants = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.5, duration: 0.5 },
  },
};

export class Movies extends Component {
  render() {
    const movies = [
      {
        image: `${sounds}`,
        title: "The Sound of Music",
        artiste: "1985",
      },
      {
        image: `${showman}`,
        title: "The Greatest Showman",
        artiste: "2017",
      },
      {
        image: `${maleficient}`,
        title: "Maleficent",
        artiste: "2014",
      },
      {
        image: `${camprock}`,
        title: "Camp Rock",
        artiste: "2010",
      },
      {
        image: `${jaws}`,
        title: "Jaws",
        artiste: "1975",
      },
    ];
    
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <motion.section
        variants={variants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="movies"
      >
        <Container>
          <h2>
            Movies
            <br />
            <span>All times favorites</span>
          </h2>
        </Container>
        <div className="movies__list">
          <Slider {...settings}>
            {movies.map((item) => (
              <div className="movies__list__item">
                <img src={item.image} alt={item.title + item.artiste} />
                <h4>{item.title}</h4>
                <p>{item.artiste}</p>
              </div>
            ))}
          </Slider>
        </div>
      </motion.section>
    );
  }
}

export default Movies;
