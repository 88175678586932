import React, { Component } from 'react'
import { motion } from 'framer-motion'
import Nav from '../nav'
import Container from '../container'

import "../../styles/about.scss";

import akanke from "../../images/Akanke.svg";

export class AboutHeader extends Component {
  render() {
    return (
      <section className="aboutheader"> 
         <Nav />
         <Container> 
           <div className="aboutheader__content">
            <motion.div initial={{opacity: 0 }} animate={{ opacity: 1}} transition={{delay: 0.7}} className="aboutheader__content__left">
              <h1>Oreoluwanimi Adeyemi</h1>
              <p className="different">Digital Product Designer, Law Student and Writer</p>
              <p>
              I am passionate about designing products that puts the user’s needs into account at every stage of the product’s lifestyle. 
              </p>
              <p>Previously, I worked with the design team at  <span className="different">UBA</span>  where I enjoyed turning user-centric ideas into well-crafted, efficient, and usable experiences.</p>
              <p>When I'm not studying, designing or strapping up my cape to save the world, I enjoy writing poetry and fictional works.</p>
            </motion.div>
            <motion.div initial={{opacity: 0, x: 250}} animate={{opacity: 1, x: 0}} transition={{delay: 1}} className="aboutheader__content__right">
              <img src={akanke} alt="Akanke" />
            </motion.div>
           </div>
         </Container>
      </section>

    )
  }
}

export default AboutHeader